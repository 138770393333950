import {
	AllThemes,
	MediaKitTwitchBlock,
	MediaKitValueStat,
	formatFollowerCount,
} from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import {
	ExternalLink,
	JulyLogo,
	Play,
	TwitchLogo,
	User,
} from "@withjuly/julycons";
import { PlatformContent } from "./PlatformContent";
import { StatCard } from "./StatCard";
import {
	CalendarBlank,
	ChartLine,
	ChatCircleDots,
	Clock,
	Timer,
	UsersThree,
} from "@withjuly/julycons/bold";
import { TimeBetween } from "~/components/Utility/Time/TimeBetween";
import { useMemo } from "react";

interface TwitchStatsProps {
	blocks: MediaKitTwitchBlock[];
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	darkMode: boolean;
}

export const TwitchStats: React.FC<TwitchStatsProps> = ({
	blocks,
	theme,
	isPreview,
	isMobilePreview,
	darkMode,
}) => {
	blocks.sort((a, b) => {
		const aSubs =
			(
				a.fields.stats.find((stat) => stat.name === "followers") as
					| MediaKitValueStat
					| undefined
			)?.value ?? 0;

		const bSubs =
			(
				b.fields.stats.find((stat) => stat.name === "followers") as
					| MediaKitValueStat
					| undefined
			)?.value ?? 0;

		return bSubs - aSubs;
	});

	return (
		<div className="flex flex-col gap-32">
			{blocks.map((block, i) => (
				<OneTwitchStats
					key={`twitch-stats-${i}`}
					indexText={
						blocks.length > 1
							? `${i + 1} of ${blocks.length} channels`
							: undefined
					}
					block={block}
					theme={theme}
					isPreview={isPreview}
					isMobilePreview={isMobilePreview}
					darkMode={darkMode}
				/>
			))}
		</div>
	);
};

interface OneTwitchStatsProps {
	block: MediaKitTwitchBlock;
	indexText?: string;
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	darkMode: boolean;
}

export const OneTwitchStats: React.FC<OneTwitchStatsProps> = ({
	block,
	indexText,
	theme,
	isPreview,
	isMobilePreview,
}) => {
	const getIcon = (name: string) => {
		let Icon = User;
		if (name === "hours_watched") {
			Icon = Clock;
		} else if (name === "highest_views") {
			Icon = ChartLine;
		} else if (name === "average_views") {
			Icon = Play;
		} else if (name === "total_live_views") {
			Icon = UsersThree;
		} else if (name === "daily_average_views") {
			Icon = CalendarBlank;
		} else if (name === "average_view_duration") {
			Icon = Timer;
		} else if (name === "daily_chat_engagement_rate") {
			Icon = ChatCircleDots;
		}
		return Icon;
	};

	const updatedAt = block.fields.stats.reduce((acc, stat) => {
		if (stat.type === "value") {
			acc.push(stat);
		}
		return acc;
	}, [] as MediaKitValueStat[])[0]?.updatedAt;

	const displayStatsGroup1 = useMemo(
		() => block.fields.stats.filter((stat) => stat.enabled),
		[block.fields.stats],
	);

	const displayStatsGroup2 = useMemo(
		() =>
			block.fields.stats.filter(
				(stat) =>
					["followers", "average_live_views", "hours_watched"].includes(
						stat.name,
					) && stat.enabled,
			),
		[block.fields.stats],
	);

	const displayStatsGroup3 = useMemo(
		() =>
			block.fields.stats.filter(
				(stat) =>
					["total_live_views", "highest_views", "daily_average_views"].includes(
						stat.name,
					) && stat.enabled,
			),
		[block.fields.stats],
	);

	const displayStatsGroup4 = useMemo(
		() =>
			block.fields.stats.filter(
				(stat) =>
					[
						"unique_views",
						"average_view_duration",
						"daily_chat_engagement_rate",
					].includes(stat.name) && stat.enabled,
			),
		[block.fields.stats],
	);

	const customStats = useMemo(
		() =>
			block.fields.customStats?.filter(
				(stat) => stat.enabled && stat.type === "custom",
			),
		[block.fields.customStats],
	);

	return (
		<div className="animate-open-fade-move-down flex h-full w-full flex-col gap-16">
			<div className="flex flex-col gap-4">
				<div className="flex items-end justify-between gap-3 ">
					<a
						href={block.fields.accountUrl}
						target="_blank"
						className="flex items-center gap-4"
					>
						<TwitchLogo className="text-white-100 h-8 w-8" />
						<div className="flex flex-col">
							<p className="text-theme-text-primary text-xl font-semibold">
								{block.fields.username}
							</p>
							{indexText ? (
								<p className="text-theme-text-secondary">{indexText}</p>
							) : null}
						</div>
					</a>

					<a
						href={block.fields.accountUrl}
						target="_blank"
						rel="noreferrer"
						className="text-theme-text-secondary flex items-center gap-1 pb-[3px] font-medium"
					>
						Go to profile <ExternalLink />
					</a>
				</div>
				<div>
					<PlatformContent block={block} isPreview={isPreview} theme={theme} />
				</div>
			</div>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex w-full items-center justify-between">
					<p className="text-theme-text-primary text-xl font-semibold leading-[1.30em]">
						Twitch Stats
					</p>
					{updatedAt ? (
						<div className="flex items-center gap-1.5">
							<p className="text-theme-text-secondary text-xs font-semibold leading-[1.30em] opacity-50">
								Updated{" "}
								<TimeBetween
									from={updatedAt}
									isMobilePreview={isMobilePreview}
								/>{" "}
								by
							</p>
							<JulyLogo className="text-theme-text-primary h-4 w-auto" />
						</div>
					) : null}
				</div>
				<div
					className={cx(
						"flex h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:hidden lg:flex-nowrap lg:gap-4",
						isPreview &&
							!isMobilePreview &&
							"lg:flex lg:flex-wrap lg:gap-0 lg:gap-y-4",
						isMobilePreview && "w-full flex-col flex-nowrap",
						displayStatsGroup1 &&
							displayStatsGroup1.length === 0 &&
							"hidden md:hidden lg:hidden",
					)}
				>
					{displayStatsGroup1.map((stat, i) => {
						if (stat.type !== "value") {
							return null;
						} else {
							const Icon = getIcon(stat.name);

							let value = formatFollowerCount(Math.round(stat.value));
							if (stat.display === "percent") {
								value = (stat.value / 100).toLocaleString("en-US", {
									style: "percent",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								});
							}

							return (
								<div
									key={`twitch-stat-${i}`}
									className={cx(
										"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:odd:last:pr-0 md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
										isPreview &&
											!isMobilePreview &&
											"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
										isMobilePreview &&
											"w-full basis-auto md:odd:pr-0 md:even:pl-0",
										!isPreview && "lg:basis-auto",
									)}
								>
									<StatCard
										title={stat.title}
										value={value}
										icon={
											<Icon className="text-theme-text-secondary h-5 w-5" />
										}
										isDetailsEnabled={stat.source !== "custom"}
										stat={stat}
									/>
								</div>
							);
						}
					})}
				</div>
				<div
					className={cx(
						"hidden h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:flex lg:flex-nowrap lg:gap-4",
						isPreview && !isMobilePreview && "md:hidden lg:hidden",
						isMobilePreview && "w-full flex-col flex-nowrap",
						displayStatsGroup2 &&
							displayStatsGroup2.length === 0 &&
							"hidden md:hidden lg:hidden",
					)}
				>
					{displayStatsGroup2.map((stat, i) => {
						if (stat.type !== "value") {
							return null;
						} else {
							const Icon = getIcon(stat.name);

							let value = formatFollowerCount(Math.round(stat.value));
							if (stat.display === "percent") {
								value = (stat.value / 100).toLocaleString("en-US", {
									style: "percent",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								});
							}

							return (
								<div
									key={`twitch-stat-${i}`}
									className={cx(
										"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
										isPreview &&
											!isMobilePreview &&
											"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
										isMobilePreview &&
											"w-full basis-auto md:odd:pr-0 md:even:pl-0",
										!isPreview && "lg:basis-auto",
									)}
								>
									<StatCard
										title={stat.title}
										value={value}
										icon={
											<Icon className="text-theme-text-secondary h-5 w-5" />
										}
										isDetailsEnabled={stat.source !== "custom"}
										stat={stat}
									/>
								</div>
							);
						}
					})}
				</div>

				<div
					className={cx(
						"hidden h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:flex lg:flex-nowrap lg:gap-4",
						isPreview && !isMobilePreview && " md:hidden lg:hidden",
						isMobilePreview && "w-full flex-col flex-nowrap",
						displayStatsGroup3 &&
							displayStatsGroup3.length === 0 &&
							"hidden md:hidden lg:hidden",
					)}
				>
					{displayStatsGroup3.map((stat, i) => {
						if (stat.type !== "value") {
							return null;
						} else {
							const Icon = getIcon(stat.name);

							let value = formatFollowerCount(Math.round(stat.value));
							if (stat.display === "percent") {
								value = (stat.value / 100).toLocaleString("en-US", {
									style: "percent",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								});
							}

							return (
								<div
									key={`twitch-stat-${i}`}
									className={cx(
										"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
										isPreview &&
											!isMobilePreview &&
											"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
										isMobilePreview &&
											"w-full basis-auto md:odd:pr-0 md:even:pl-0",
										!isPreview && "lg:basis-auto",
									)}
								>
									<StatCard
										title={stat.title}
										value={value}
										icon={
											<Icon className="text-theme-text-secondary h-5 w-5" />
										}
										isDetailsEnabled={stat.source !== "custom"}
										stat={stat}
									/>
								</div>
							);
						}
					})}
				</div>
				<div
					className={cx(
						"flex hidden h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:flex lg:flex-nowrap lg:gap-4",
						isPreview && !isMobilePreview && " md:hidden lg:hidden",
						isMobilePreview && "w-full flex-col flex-nowrap",
						displayStatsGroup4 &&
							displayStatsGroup4.length === 0 &&
							"hidden md:hidden lg:hidden",
					)}
				>
					{displayStatsGroup4.map((stat, i) => {
						if (stat.type !== "value") {
							return null;
						} else {
							const Icon = getIcon(stat.name);

							let value = formatFollowerCount(Math.round(stat.value));
							if (stat.display === "percent") {
								value = (stat.value / 100).toLocaleString("en-US", {
									style: "percent",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								});
							}

							return (
								<div
									key={`twitch-stat-${i}`}
									className={cx(
										"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
										isPreview &&
											!isMobilePreview &&
											"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
										isMobilePreview &&
											"w-full basis-auto md:odd:pr-0 md:even:pl-0",
										!isPreview && "lg:basis-auto",
									)}
								>
									<StatCard
										title={stat.title}
										value={value}
										icon={
											<Icon className="text-theme-text-secondary h-5 w-5" />
										}
										isDetailsEnabled={stat.source !== "custom"}
										stat={stat}
									/>
								</div>
							);
						}
					})}
				</div>
				<div
					className={cx(
						"flex h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:flex-nowrap lg:gap-4",
						isPreview &&
							!isMobilePreview &&
							"lg:flex lg:flex-wrap lg:gap-0 lg:gap-y-4",
						isMobilePreview && "w-full flex-col flex-nowrap",
						customStats &&
							customStats.length === 0 &&
							"hidden md:hidden lg:hidden",
					)}
				>
					{customStats
						? customStats.map((stat, i) => {
								return (
									<div
										key={`twitch-stat-${i}`}
										className={cx(
											"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:odd:last:pr-0 md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
											isPreview &&
												!isMobilePreview &&
												"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
											isMobilePreview &&
												"w-full basis-auto md:odd:pr-0 md:even:pl-0",
											!isPreview && "lg:basis-auto",
										)}
									>
										<StatCard
											title={stat.title}
											value={stat.value.toString()}
											isDetailsEnabled={true}
											stat={stat}
										/>
									</div>
								);
							})
						: null}
				</div>
			</div>
		</div>
	);
};
