import {
	MediaKitCustomPlatformValueStat,
	MediaKitValueStat,
} from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { Info } from "@withjuly/julycons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@withjuly/solis";
import { useState } from "react";
import { TimeBetween } from "~/components/Utility/Time/TimeBetween";

interface StateCardProps {
	icon?: React.ReactElement;
	title: string;
	value: string;
	tooltipText?: string;
	stat?: MediaKitValueStat | MediaKitCustomPlatformValueStat;
	isDetailsEnabled?: boolean;
}

export const StatCard: React.FC<StateCardProps> = ({
	icon,
	title,
	value,
	stat,
	isDetailsEnabled = false,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const getConnectionType = () => {
		if (stat) {
			if (stat.source === "custom") {
				return ["Provided by agency", "Data is provided by the agency"];
			} else if (stat.source === "no-auth") {
				return [
					"Third-party data",
					"Data is provided by a third-party data collection service.",
				];
			} else if (stat.source === "tiktok") {
				return [
					"Via TikTok",
					"Data is provided using a direct API connection with TikTok",
				];
			} else if (stat.source === "youtube") {
				return [
					"Via YouTube",
					"Data is provided using a direct API connection with YouTube",
				];
			} else {
				return [
					`Via ${stat.source?.slice(0, 1).toUpperCase()}${stat.source?.slice(1)}`,
					`Data is provided using a direct API connection with ${stat.source?.slice(0, 1).toUpperCase()}${stat.source?.slice(1)}`,
				];
			}
		} else {
			return ["", ""];
		}
	};

	return (
		<div
			className={cx(
				`bg-theme-fill text-theme-text-secondary border-theme-stroke flex h-[120px] w-full flex-col items-start rounded-2xl border transition-transform`,
				isDetailsEnabled && "hover:scale-[1.02]",
			)}
			role={isDetailsEnabled ? "button" : ""}
			onClick={() => setIsOpen(!isOpen)}
		>
			{stat && isDetailsEnabled && isOpen ? (
				<div className="bg-theme-fill h-full w-full rounded-2xl">
					<div className="text-kit-text border-theme-stroke flex w-full items-center justify-between border-b px-[14px] py-3">
						<div className="font-repro bg-theme-button-fill text-theme-text-button flex h-[21px] w-fit items-center gap-1 rounded-full px-2 py-[2px] text-[12px]">
							{getConnectionType()[0]}
							<Tooltip>
								<TooltipTrigger>
									<Info className="text-theme-text-button h-4 w-4" />
								</TooltipTrigger>
								<TooltipContent className="bg-theme-fill text-theme-text-primary border-theme-stroke">
									{getConnectionType()[1]}
								</TooltipContent>
							</Tooltip>
						</div>
						{stat.type !== "custom" && stat.updatedAt ? (
							<p className="text-theme-text-secondary text-[12px]">
								Updated <TimeBetween from={stat.updatedAt} />
							</p>
						) : null}
					</div>

					<div className="flex px-4 pb-4 pt-2">
						<p className="text-theme-text-primary text-[12px] font-semibold">
							{stat.description}
						</p>
					</div>
				</div>
			) : (
				<div className="flex w-full flex-col gap-3 py-[20px] pl-6 pr-[20px]">
					<div className="flex w-full justify-between">
						<div className="text-kit-text flex items-center gap-[6px]">
							{icon}
							<p className="text-body-xl text-theme-text-secondary line-clamp-1 w-full overflow-ellipsis break-all">
								{title.replaceAll("Average", "Avg.")}
							</p>
						</div>
						{isDetailsEnabled ? (
							<Tooltip>
								<TooltipTrigger>
									<Info className="text-theme-text-secondary h-4 w-4" />
								</TooltipTrigger>
								<TooltipContent className="bg-theme-fill text-theme-text-primary border-theme-stroke">
									Click to show more
								</TooltipContent>
							</Tooltip>
						) : null}
					</div>
					<p className="text-theme-text-primary truncate text-[32px] font-semibold">
						{value}
					</p>
				</div>
			)}
		</div>
	);
};
